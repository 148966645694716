import { ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { IWarrantyOption } from '../../../../actions/DataServiceEntities.g';
import { getWarrantyOptionByPeriod } from '../../../../utilities/warranty/utils';
import _uniqueId from 'lodash/uniqueId';

export interface IWarrantyOptionsControlProps {
    warrantyOptions: IWarrantyOption[];
    resources: IWarrantyControlResourceData;
    className?: string;
    telemetryContent?: ITelemetryContent;
    design?: string;
    selectedWarrantyOption?: IWarrantyOption;
    isSelectWarrantyDisabled?: boolean;

    onSelectWarrantyOption?(selectedWarrantyOption: IWarrantyOption | undefined): void;
}

export interface IWarrantyOptionsControlState {
    selectedWarrantyOption: IWarrantyOption | undefined;
    isSelectWarrantyDisabled: boolean | undefined;
}

export interface IWarrantyControlResourceData {
    textPriceBridge: string;
    textNoSelection: string;
}

/**
 *
 * Warranty options control component.
 * @extends {React.PureComponent<IWarrantyOptionsControlProps, IWarrantyOptionsControlState>}
 */
export class WarrantyOptionsControl extends React.PureComponent<IWarrantyOptionsControlProps, IWarrantyOptionsControlState> {
    private controlGroupId: string;

    constructor(props: IWarrantyOptionsControlProps) {
        super(props);

        this.state = {
            selectedWarrantyOption: props.selectedWarrantyOption ?? undefined,
            isSelectWarrantyDisabled: false
        };
        this.controlGroupId = _uniqueId('product-warranty-selection');
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    /**
     * When attached to the radio button set, updates the state of this component to match the user's selection.
     *
     * @param event - The event triggered by the user selecting an new radio button.
     */
    private onChangeValue(event: any) {
        const selectedWarrantyOptionPeriod = event.target?.value;
        const selectedWarrantyOption = getWarrantyOptionByPeriod(this.props.warrantyOptions, selectedWarrantyOptionPeriod);
        this.setState({
            selectedWarrantyOption: selectedWarrantyOption
        });
        if (this.props.onSelectWarrantyOption) {
            this.props.onSelectWarrantyOption(selectedWarrantyOption);
        }
    }

    public _updateDisabledState = (state: boolean) => {
        this.setState({
            isSelectWarrantyDisabled: state
        });
    };

    private setDefaultWarranty() {
        if (!this.state.selectedWarrantyOption) {
            const defaultWarrantyOption = this.props.selectedWarrantyOption
                ? getWarrantyOptionByPeriod(this.props.warrantyOptions, this.props.selectedWarrantyOption.Period)
                : getWarrantyOptionByPeriod(this.props.warrantyOptions, 0);
            this.setState({
                selectedWarrantyOption: defaultWarrantyOption
            });
        }
    }

    public static getDerivedStateFromProps(props: any, state: any) {
        if (props.isSelectWarrantyDisabled !== state.isSelectWarrantyDisabled) {
            return {
                selectedWarrantyOption: state.selectedWarrantyOption,
                isSelectWarrantyDisabled: props.isSelectWarrantyDisabled
            };
        }
        //no change in state
        return null;
    }

    public render(): JSX.Element {
        this.setDefaultWarranty();
        switch (this.props.design) {
            case 'faithful':
                return (
                    <div onChange={this.onChangeValue}>
                        {this.props.warrantyOptions.map((warrantyOption: IWarrantyOption) => {
                            return this._renderRadioButton(warrantyOption);
                        })}
                    </div>
                );
            case 'button':
                return this._renderWarrantyOptionsControlGrouped(this.props);
            case 'grouped':
                return this._renderWarrantyOptionsControlGrouped(this.props);
            default:
                return (
                    <div onChange={this.onChangeValue}>
                        {this.props.warrantyOptions.map((warrantyOption: IWarrantyOption) => {
                            return this._renderRadioButton(warrantyOption);
                        })}
                    </div>
                );
        }
    }

    private _renderWarrantyOptionsControlGrouped(props: IWarrantyOptionsControlProps) {
        return (
            <div className={`${props.className}__control`} onChange={this.onChangeValue}>
                {this.props.warrantyOptions.map((warrantyOption: IWarrantyOption) => {
                    return this._renderRadioButtonGrouped(warrantyOption);
                })}
            </div>
        );
    }

    private _renderRadioButton(warrantyOption: IWarrantyOption) {
        if (warrantyOption.Period === 0) {
            return (
                <div
                    key={warrantyOption.TermName}
                    className={this.state.isSelectWarrantyDisabled ?? false ? 'radio radio-disabled' : 'radio'}
                >
                    <label>
                        <input
                            type='radio'
                            name={this.controlGroupId}
                            value={warrantyOption.Period}
                            checked={this.state.selectedWarrantyOption?.Period === warrantyOption.Period}
                            disabled={this.state.isSelectWarrantyDisabled ?? false}
                        />
                        {this.props.resources.textNoSelection}
                    </label>
                </div>
            );
        } else {
            return (
                <div
                    key={warrantyOption.TermName}
                    className={this.state.isSelectWarrantyDisabled ?? false ? 'radio radio-disabled' : 'radio'}
                >
                    <label>
                        <input
                            type='radio'
                            name={this.controlGroupId}
                            value={warrantyOption.Period}
                            checked={this.state.selectedWarrantyOption?.Period === warrantyOption.Period}
                            disabled={this.state.isSelectWarrantyDisabled ?? false}
                        />
                        <strong>{warrantyOption.TermName}</strong> {this.props.resources.textPriceBridge}{' '}
                        <strong className={`${this.props.className}__price`}>${warrantyOption.Charge}</strong>
                    </label>
                </div>
            );
        }
    }

    private _renderRadioButtonGrouped(warrantyOption: IWarrantyOption) {
        const termInfo = warrantyOption.TermName?.split('. ') ?? ['', ''];
        if (warrantyOption.Period === 0) {
            return (
                <div key={warrantyOption.TermName} className={`${this.props.className}__radio`}>
                    <div className={this.state.isSelectWarrantyDisabled ?? false ? 'radio radio-disabled' : 'radio'}>
                        <input
                            type='radio'
                            name={this.controlGroupId}
                            value={warrantyOption.Period}
                            checked={this.state.selectedWarrantyOption?.Period === warrantyOption.Period}
                            disabled={this.state.isSelectWarrantyDisabled ?? false}
                        />
                        <label>
                            <strong>{this.props.resources.textNoSelection}</strong>
                        </label>
                    </div>
                </div>
            );
        } else {
            return (
                <div key={warrantyOption.TermName} className={`${this.props.className}__radio`}>
                    <div className={this.state.isSelectWarrantyDisabled ?? false ? 'radio radio-disabled' : 'radio'}>
                        <input
                            type='radio'
                            name={this.controlGroupId}
                            value={warrantyOption.Period}
                            checked={this.state.selectedWarrantyOption?.Period === warrantyOption.Period}
                            disabled={this.state.isSelectWarrantyDisabled ?? false}
                        />
                        <label>
                            <div className='product-warranty-content'>
                                <strong>{termInfo[0].valueOf()}</strong>
                                <br />
                                <strong>{termInfo[1].valueOf()}</strong>
                                <br />
                                <strong className={`${this.props.className}__price`}>${warrantyOption.Charge}</strong>
                            </div>
                        </label>
                    </div>
                </div>
            );
        }
    }
}

export default WarrantyOptionsControl;
