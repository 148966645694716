import { ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

export interface IWarrantyIconProps {
    resources: IWarrantyIconResourceData;

    className?: string;
    iconWidth?: number;
    iconHeight?: number;
    iconDimensionsUnit?: string;
    telemetryContent?: ITelemetryContent;
}

export interface IWarrantyIconState {
    isSrcValid: boolean;
}

export interface IWarrantyIconResourceData {
    imageAltText: string;
    imageSrc: string;
}

/**
 * Warranty Icon component.
 * @extends {React.PureComponent<IWarrantyIconProps>}
 */
export class WarrantyIcon extends React.PureComponent<IWarrantyIconProps, IWarrantyIconState> {
    constructor(props: IWarrantyIconProps) {
        super(props);
        this.state = {
            isSrcValid: true
        };
    }

    protected onError = () => {
        if (!this.state.isSrcValid) {
            this.setState({
                isSrcValid: false
            });
        }
    };

    public render(): JSX.Element {
        return (
            <div className={`${this.props.className}`}>
                <img src={this.props.resources.imageSrc} style={{ display: this.state.isSrcValid ? 'visible' : 'none' }} />
            </div>
        );
    }
}

export default WarrantyIcon;
