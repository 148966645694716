import { IProductWarranties, IWarrantyOption } from '../../actions/DataServiceEntities.g';
import { CommerceProperty, CommercePropertyValue } from '@msdyn365-commerce/retail-proxy';

export interface IPairedWarrantyLine<T> {
    item: T;
    warranty: T | undefined;
}

interface ILineItem {
    data?: any;
}

export enum WarrantyLineDataType {
    ICartlinesViewProps = 'cartline',
    IFlyoutCartLineItemViewProps = 'cartline',
    ISalesLine = 'salesLine'
}

export enum WarrantyLineCommerceProperties {
    isWarrantyLine = 'Memx_IsWarrantyLine',
    warrantableLineNumber = 'Memx_WarrantableLineNumber',
    warrantyPeriod = 'Memx_WarrantyPeriod'
}

/**
 * Determins if an item line is a warranty line.
 * @param itemLines the itemLine to evaluate.
 * @param lineDataType the data item within the object. This should be the WarrantyLineCommerceProperty where the name matches the value of T.
 */
export function getIsWarrantyLine<T extends ILineItem>(itemLine: T, lineDataType: WarrantyLineDataType): boolean {
    return getWarrantyLineProperty(itemLine, lineDataType, WarrantyLineCommerceProperties.isWarrantyLine)?.BooleanValue ?? false;
}

/**
 * Retreives a warranty line property from an item line.
 * @param itemLines the itemLine to evaluate.
 * @param lineDataType the data item within the object. This should be the WarrantyLineCommerceProperty where the name matches the value of T.
 * @param commercePropName The name of the commerce property to search for.
 */
export function getWarrantyLineProperty<T extends ILineItem>(
    itemLine: T,
    lineDataType: WarrantyLineDataType,
    commercePropName: WarrantyLineCommerceProperties
): CommercePropertyValue {
    return itemLine.data[lineDataType].ExtensionProperties?.find((commerceProp: CommerceProperty) => commerceProp.Key === commercePropName)
        ?.Value;
}

/**
 * Determines if item line number matches value provided.
 * @param value The number to compare to the provided itemLine's line number.
 * @param itemLines the itemLine to compare the value to.
 * @param lineDataType the data item within the object. This should be the WarrantyLineCommerceProperty where the name matches the value of T.
 */
export function getDoesValueMatchLineNumber<T extends ILineItem>(
    value: number | undefined,
    itemLine: T,
    lineDataType: WarrantyLineDataType
) {
    return itemLine.data[lineDataType].LineNumber === value;
}

/**
 * Given a set of Line items, returns a list of paired line items,
 * where cart line items are paired with their associated warranties.
 * @param itemLines the list of itemLines to pair.
 * @param lineDataType the data item within the object. This should be the WarrantyLineCommerceProperty where the name matches the value of T.
 */
export function pairWarrantyItemLines<T extends ILineItem>(itemLines: T[], lineDataType: WarrantyLineDataType): IPairedWarrantyLine<T>[] {
    const pairedItemLines: IPairedWarrantyLine<T>[] = [];
    const warrantyItemLines: T[] = [];

    let lastItemLine: T | undefined;

    itemLines.forEach(function(itemLine) {
        const isWarranty: boolean = getIsWarrantyLine<T>(itemLine, lineDataType);
        if (isWarranty) {
            if (
                lastItemLine &&
                itemLine.data &&
                getDoesValueMatchLineNumber(
                    getWarrantyLineProperty<T>(itemLine, lineDataType, WarrantyLineCommerceProperties.warrantableLineNumber)?.DecimalValue,
                    itemLine,
                    lineDataType
                )
            ) {
                pairedItemLines.push({ item: lastItemLine, warranty: itemLine });
                lastItemLine = undefined;
            } else {
                warrantyItemLines.push(itemLine);
            }
        } else {
            if (lastItemLine) {
                pairedItemLines.push({ item: lastItemLine, warranty: undefined });
            }
            lastItemLine = itemLine;
        }
    });

    if (lastItemLine) {
        pairedItemLines.push({ item: lastItemLine, warranty: undefined });
    }

    warrantyItemLines.forEach(function(warrantyItemLine) {
        if (warrantyItemLine.data) {
            const warrantableLineNumber = getWarrantyLineProperty<T>(
                warrantyItemLine,
                lineDataType,
                WarrantyLineCommerceProperties.warrantableLineNumber
            );
            const warrantableItemLineIndex = pairedItemLines.findIndex(
                pairedItemLines =>
                    pairedItemLines.item.data &&
                    getDoesValueMatchLineNumber(warrantableLineNumber?.DecimalValue, pairedItemLines.item, lineDataType)
            );
            if (warrantableItemLineIndex >= 0) {
                const pairedItemLine = pairedItemLines[warrantableItemLineIndex];
                pairedItemLines[warrantableItemLineIndex] = { item: pairedItemLine.item, warranty: warrantyItemLine };
            }
        }
    });

    return pairedItemLines;
}

/**
 * Searches the provided list of cartlines for the provided item to determine what its
 * currently selected warranty is.
 * @param recordId Id of the product which
 * @param pairedCartLine The warranty in this item/warranty pair contains the list on which the
 * selected item is searched.
 * @param productWarrantiesForCartline The list of active product warranties in the cart. Most modules that
 * concern the cart have this in their data actions.
 */
export function findSelectedWarranty<T extends ILineItem>(
    recordId: number,
    pairedItemLine: IPairedWarrantyLine<T>,
    productWarrantiesForItemLine: IProductWarranties | undefined,
    lineDataType: WarrantyLineDataType
): IWarrantyOption | undefined {
    if (
        productWarrantiesForItemLine &&
        productWarrantiesForItemLine.WarrantyGroups &&
        productWarrantiesForItemLine?.WarrantyGroups[0].WarrantyOptions &&
        productWarrantiesForItemLine?.WarrantyGroups[0].WarrantyOptions.length > 0
    ) {
        let selectedWarranty = productWarrantiesForItemLine?.WarrantyGroups[0].WarrantyOptions.find(
            warrantyOption =>
                warrantyOption.Period ===
                pairedItemLine.warranty?.data[lineDataType].ExtensionProperties?.find(
                    (commerceProp: CommerceProperty) => commerceProp.Key === WarrantyLineCommerceProperties.warrantyPeriod
                )?.Value?.IntegerValue
        );
        if (selectedWarranty) {
            return selectedWarranty;
        }
        return getEmptyWarrantyOption(recordId ?? 0, productWarrantiesForItemLine?.WarrantyGroups[0].WarrantyOptions);
    }
    return undefined;
}

/**
 * Returns a full warranty option from the provided list based on the provided term name.
 *
 * @param warrantyOptions A list of warranty options for a product.
 * @param period The duration the warranty is valid for, in string or number format. (note that warranty options have no unique id)
 */
export function getWarrantyOptionByPeriod(warrantyOptions: IWarrantyOption[], period: string | number): IWarrantyOption | undefined {
    if (typeof period === 'string') {
        period = parseInt(period, 10);
    }
    if (period === 0) {
        return getEmptyWarrantyOption(0, warrantyOptions);
    } else {
        return getWarrantyOptionsInternal(warrantyOptions, period);
    }
}

/*
 * Internal function. Returns a full warranty option from the provided list based on the provided term name.
 *
 * @param warrantyOptions - A list of warranty options for a product.
 * @param period - The duration the warranty is valid for. (note that warranty options have no unique id)
 */
function getWarrantyOptionsInternal(warrantyOptions: IWarrantyOption[], period: number): IWarrantyOption | undefined {
    return warrantyOptions.find(option => {
        return option.Period === period;
    });
}

/**
 * Returns a warranty with a period of 0. This function can be called without warranty options, but this is not recommended.
 *
 * @param warrantyOptions A list of warranty options for a product.
 * @param period The duration the warranty is valid for, in string or number format. (note that warranty options have no unique id)
 * @param warrantyType The associated warrranty type (e.g. EW/IPR).
 */
export function getEmptyWarrantyOption(
    warrantableProduct: number,
    warrantyOptions?: IWarrantyOption[],
    warrantyType?: string
): IWarrantyOption | undefined {
    let emptyWarranty: IWarrantyOption | undefined;
    if (warrantyOptions) {
        emptyWarranty = getWarrantyOptionsInternal(warrantyOptions, 0);
    }
    if (!emptyWarranty) {
        const emptyWarrantyType = warrantyType ?? (warrantyOptions ? warrantyOptions[0].Type : undefined);
        emptyWarranty = {
            Charge: 0,
            Description: 'None',
            Period: 0,
            Product: 0,
            Rate: 0,
            RecordId: 1,
            TermName: `${emptyWarrantyType} None`,
            Type: emptyWarrantyType,
            WarrantableProduct: +warrantableProduct,
            WarrantableProductPrice: 0
        };
    }
    return emptyWarranty;
}
