import { IButtonProps } from '@msdyn365-commerce-modules/utilities';
import { IProductWarranties, IWarrantyIcon, IWarrantyOption } from '../../../actions/DataServiceEntities.g';
import { IWarrantyHeaderProps, IWarrantyHeaderResourceData } from './components/warranty-header';
import { IWarrantyIconProps, IWarrantyIconResourceData } from './components/warranty-icon';
import { IWarrantyControlResourceData, IWarrantyOptionsControlProps } from './components/warranty-options-control';
import { ILinkData } from '@msdyn365-commerce/core-internal';

export enum WarrantyType {
    EW = 'EW',
    IPR = 'IPR'
}

export enum ProductWarrantyNameDictionary {
    EW = 'Extended Warranty',
    IPR = 'In-Store Product Replacement',
    Service = 'Service'
}

export interface IProductWarrantyComponentState {
    selectedWarrantyOption: IWarrantyOption | undefined;
    isSelectWarrantyDisabled: boolean | undefined;
}

export interface IProductWarrantyComponentProps extends React.HTMLAttributes<HTMLElement> {
    productWarranties: IProductWarranties;
    selectedWarranty?: IWarrantyOption;

    className?: string;
    design?: string;
    warrantyIcons?: IWarrantyIcon[];
    isSelectWarrantyDisabled?: boolean | undefined;

    productQuantity?: number;

    showHeader?: boolean;
    showIcon?: boolean;
    showDetailButton?: boolean;
    learnMoreLink?: ILinkData;

    onSelectWarrantyOption?(selectedWarrantyOption: IWarrantyOption | undefined): any;

    iprProductWarrantyResources?: Partial<IProductWarrantyResources>;
    ewProductWarrantyResources?: Partial<IProductWarrantyResources>;
}

export interface IProductWarrantyViewProps {
    warrantyType: WarrantyType;
    warrantyOptions: IWarrantyOption[];
    productWarrantyText: IProductWarrantyResources;

    warrantyHeader: IWarrantyHeaderProps;
    warrantyOptionsControl: IWarrantyOptionsControlProps;
    warrantyButton: IButtonProps;
    warrantyIcon: IWarrantyIconProps;

    className: string;
    showHeader: boolean;
    showIcon: boolean;
    showDetailButton: boolean;

    warrantyDisabledMessage?: string;
}

export interface IProductWarrantyViewState {
    selectedWarrantyOption: IWarrantyOption | undefined;
}

export interface IProductWarrantyResources {
    iconResources: IWarrantyIconResourceData;
    headerResources: IWarrantyHeaderResourceData;
    optionsControlResources: IWarrantyControlResourceData;
    textResource: string;
}
