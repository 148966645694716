import * as React from 'react';
import { Node } from '@msdyn365-commerce-modules/utilities';
import {
    IProductWarrantyComponentProps,
    WarrantyType,
    IProductWarrantyViewProps,
    IProductWarrantyComponentState,
    IProductWarrantyResources,
    ProductWarrantyNameDictionary
} from './product-warranty.props';
import { Button } from 'reactstrap';
import { IWarrantyHeaderProps, WarrantyHeader } from './components/warranty-header';
import { IWarrantyIconProps, WarrantyIcon } from './components/warranty-icon';
import { IWarrantyOptionsControlProps, WarrantyOptionsControl } from './components/warranty-options-control';
import { IWarrantyGroup, IWarrantyOption } from '../../../actions/DataServiceEntities.g';

export const DISABLE_WARRANTY_MULTIPLE_QUANTITY_MESSAGE = 'Warranty not selectable when selected quantity is greater than 1';

/**
 * Product warranty component.
 * @extends {React.PureComponent<IWarrantyProductWarrantyComponentProps, IProductWarrantyComponentState>}
 */
export class ProductWarrantyComponent extends React.PureComponent<IProductWarrantyComponentProps, IProductWarrantyComponentState> {
    public props: IProductWarrantyComponentProps;

    constructor(props: IProductWarrantyComponentProps) {
        super(props);
        this.props = props;

        this.state = {
            selectedWarrantyOption: props.selectedWarranty ?? undefined,
            isSelectWarrantyDisabled: props.isSelectWarrantyDisabled ?? false
        };

        this._updateDisabledState = this._updateDisabledState.bind(this);
    }

    public toggleSelectWarrantyDisabled = () => {
        this.setState({ isSelectWarrantyDisabled: !this.state.isSelectWarrantyDisabled });
    };

    public _updateDisabledState = (state: boolean) => {
        this.setState({
            isSelectWarrantyDisabled: state
        });
    };

    public _getPropTextByWarrantyType(warrantyType: WarrantyType): IProductWarrantyResources {
        const imageIconSrc = this.props.warrantyIcons?.find(icon => warrantyType.toString() === icon.WarrantyType)?.Image;

        return {
            iconResources: {
                imageSrc: imageIconSrc ? `data:image/gif;base64,${imageIconSrc}` : '',
                imageAltText: warrantyType.toString()
            },
            headerResources: {
                textTitle: `${ProductWarrantyNameDictionary[warrantyType.toString()]} (${warrantyType.toString()})`,
                textSubtitle: `Protect your purchase with an ${warrantyType.toString()} plan`
            },
            optionsControlResources: {
                textNoSelection: 'None',
                textPriceBridge: '- only'
            },
            textResource: `Learn more about ${warrantyType.toString()}`
        };
    }

    public _setSelectedWarrantyOption = (selectedWarrantyOption: IWarrantyOption | undefined) => {
        this.setState({
            selectedWarrantyOption: selectedWarrantyOption
        });
        if (this.props.onSelectWarrantyOption) {
            this.props.onSelectWarrantyOption(selectedWarrantyOption);
        }
    };

    public static getDerivedStateFromProps(props: any, state: any) {
        if (props.isSelectWarrantyDisabled !== state.isSelectWarrantyDisabled) {
            return {
                selectedWarrantyOption: state.selectedWarrantyOption,
                isSelectWarrantyDisabled: props.isSelectWarrantyDisabled
            };
        }
        //no change in state
        return null;
    }

    public render(): JSX.Element | null {
        if (this.props.productWarranties) {
            const warrantyGroups = this.props.productWarranties.WarrantyGroups;

            if (warrantyGroups && warrantyGroups[0]) {
                const usedWarrantyGroup: IWarrantyGroup = warrantyGroups[0];
                const warrantyOptions: IWarrantyOption[] = usedWarrantyGroup.WarrantyOptions ?? [];

                if (warrantyOptions) {
                    var warrantyType: WarrantyType;

                    if (usedWarrantyGroup.Type && usedWarrantyGroup.Type.includes(WarrantyType.EW)) {
                        warrantyType = WarrantyType.EW;
                    } else if (usedWarrantyGroup.Type && usedWarrantyGroup.Type.includes(WarrantyType.IPR)) {
                        warrantyType = WarrantyType.IPR;
                    } else {
                        return this._renderViewNoWarranties();
                    }

                    const productWarrantyText = this._getPropTextByWarrantyType(warrantyType);
                    const className = 'mmx-product-warranty';
                    warrantyOptions.sort((a, b) => a.Period - b.Period);
                    const productWarrantyViewProps: IProductWarrantyViewProps = {
                        ...this.props,
                        productWarrantyText: productWarrantyText,
                        warrantyType: warrantyType,
                        warrantyOptions: warrantyOptions,
                        warrantyOptionsControl: {
                            design: this.props.design,
                            warrantyOptions: warrantyOptions,
                            resources: productWarrantyText.optionsControlResources,
                            className: `${className}`,
                            selectedWarrantyOption: this.props.selectedWarranty,
                            isSelectWarrantyDisabled: this.state.isSelectWarrantyDisabled,
                            onSelectWarrantyOption: this._setSelectedWarrantyOption
                        },
                        warrantyHeader: {
                            resources: productWarrantyText.headerResources,
                            className: `${className}__header-title`
                        },
                        warrantyButton: {
                            title: 'Learn More button',
                            className: `${className}__more-info`,
                            href: this.props.learnMoreLink?.destinationUrl
                        },
                        warrantyIcon: {
                            resources: productWarrantyText.iconResources,
                            className: `${className}__stamp`
                        },
                        className: className,
                        showHeader: this.props.showHeader ?? true,
                        showIcon: this.props.showIcon ?? true,
                        showDetailButton: this.props.showDetailButton ?? true,
                        warrantyDisabledMessage:
                            this.props.productQuantity && this.props.productQuantity > 1
                                ? DISABLE_WARRANTY_MULTIPLE_QUANTITY_MESSAGE
                                : undefined
                    };

                    const DEFAULTMOCK = this._getFaithfulView(productWarrantyViewProps);
                    switch (this.props.design) {
                        case 'faithful':
                            return this._getFaithfulView(productWarrantyViewProps);
                        case 'button':
                            return this._getButtonView(productWarrantyViewProps);
                        case 'grouped':
                            return this._getGroupedButtonView(productWarrantyViewProps);
                        default:
                            return DEFAULTMOCK;
                    }
                }
            }
        }
        return this._renderViewNoWarranties();
    }

    protected _renderWarrantyIcon = (props: IWarrantyIconProps): JSX.Element => {
        return <WarrantyIcon {...props} />;
    };

    protected _renderWarrantyHeader = (props: IWarrantyHeaderProps): JSX.Element => {
        return <WarrantyHeader {...props} />;
    };

    protected _renderWarrantyOptionsControl = (props: IWarrantyOptionsControlProps): JSX.Element => {
        return <WarrantyOptionsControl {...props} onSelectWarrantyOption={props.onSelectWarrantyOption} />;
    };

    protected _renderWarrantyButton = (props: any, text: string): JSX.Element => {
        return <Button {...props}>{text}</Button>;
    };

    protected _renderWarrantyDisabledMessage = (message: string) => {
        return <div className='mmx-alert'>{message}</div>;
    };

    protected _getFaithfulView(props: IProductWarrantyViewProps): JSX.Element | null {
        const nodeFaithfulView = { className: props.className };
        const nodeWarrantyContent = { className: `${props.className}__content` };
        const nodeWarrantyHeader = { className: `${props.className}__header-block` };
        return (
            <div>
                <Node {...nodeFaithfulView}>
                    <Node {...nodeWarrantyContent}>
                        <Node {...nodeWarrantyHeader}>
                            {props.showIcon && props.warrantyIcon && this._renderWarrantyIcon(props.warrantyIcon)}
                            {props.showHeader && props.warrantyHeader && this._renderWarrantyHeader(props.warrantyHeader)}
                        </Node>
                        {props.warrantyDisabledMessage && this._renderWarrantyDisabledMessage(props.warrantyDisabledMessage)}
                        {props.warrantyOptionsControl && this._renderWarrantyOptionsControl(props.warrantyOptionsControl)}
                        {props.showDetailButton &&
                            props.warrantyButton &&
                            this._renderWarrantyButton(props.warrantyButton, props.productWarrantyText.textResource)}
                    </Node>
                </Node>
            </div>
        );
    }

    protected _getButtonView(props: IProductWarrantyViewProps): JSX.Element | null {
        const nodeButtonView = { className: `${props.className}__buttons` };
        const nodeWarrantyContent = { className: `${props.className}__content` };
        const nodeWarrantyHeader = { className: `${props.className}__header-block` };
        return (
            <div>
                <Node {...nodeButtonView}>
                    <Node {...nodeWarrantyContent}>
                        <Node {...nodeWarrantyHeader}>
                            {props.showIcon && props.warrantyIcon && this._renderWarrantyIcon(props.warrantyIcon)}
                            {props.showHeader && props.warrantyHeader && this._renderWarrantyHeader(props.warrantyHeader)}
                        </Node>
                        {props.warrantyDisabledMessage && this._renderWarrantyDisabledMessage(props.warrantyDisabledMessage)}
                        {props.warrantyOptionsControl && this._renderWarrantyOptionsControl(props.warrantyOptionsControl)}
                        {props.showDetailButton &&
                            props.warrantyButton &&
                            this._renderWarrantyButton(props.warrantyButton, props.productWarrantyText.textResource)}
                    </Node>
                </Node>
            </div>
        );
    }

    protected _getGroupedButtonView(props: IProductWarrantyViewProps): JSX.Element | null {
        const nodeGroupedButtonView = { className: `${props.className}__grouped` };
        const nodeWarrantyContent = { className: `${props.className}__content` };
        const nodeWarrantyHeader = { className: `${props.className}__header-block` };
        return (
            <div>
                <Node {...nodeGroupedButtonView}>
                    <Node {...nodeWarrantyContent}>
                        <Node {...nodeWarrantyHeader}>
                            {props.showIcon && props.warrantyIcon && this._renderWarrantyIcon(props.warrantyIcon)}
                            {props.showHeader && props.warrantyHeader && this._renderWarrantyHeader(props.warrantyHeader)}
                        </Node>
                        {props.warrantyDisabledMessage && this._renderWarrantyDisabledMessage(props.warrantyDisabledMessage)}
                        {props.warrantyOptionsControl && this._renderWarrantyOptionsControl(props.warrantyOptionsControl)}
                        {props.showDetailButton &&
                            props.warrantyButton &&
                            this._renderWarrantyButton(props.warrantyButton, props.productWarrantyText.textResource)}
                    </Node>
                </Node>
            </div>
        );
    }

    protected _renderViewNoWarranties(): JSX.Element | null {
        return <></>;
    }
}

export default ProductWarrantyComponent;
