import { Heading, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

export interface IWarrantyHeaderProps {
    resources: IWarrantyHeaderResourceData;
    className?: string;
    telemetryContent?: ITelemetryContent;
}

export interface IWarrantyHeaderResourceData {
    textTitle: string;
    textSubtitle: string;
}

/**
 *
 * Warranty header component.
 * @extends {React.PureComponent<IWarrantyHeaderProps>}
 */
export class WarrantyHeader extends React.PureComponent<IWarrantyHeaderProps> {
    constructor(props: IWarrantyHeaderProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className={this.props.className}>
                <Heading
                    {...{
                        text: this.props.resources.textTitle ?? '',
                        className: `${this.props.className}__title`,
                        headingTag: 'h3'
                    }}
                />
                <small className={`${this.props.className}__subtitle`}>{this.props.resources.textSubtitle}</small>
            </div>
        );
    }
}

export default WarrantyHeader;
